import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CardDeck from "react-bootstrap/CardDeck"
import { Row, Col, Card} from "react-bootstrap"
import Img from "gatsby-image"

const Featured = () => {

    const { gcms: { productsApi } } = useStaticQuery(pageQuery)
    return (
        <Layout>
            <SEO title="Featured" />
            <Row>
                <Col className="mb-5">
                    <h1>New In Stock</h1>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex">
                    <CardDeck
                        className="mx-auto justify-content-center"
                    >
                        {productsApi.map(product => (
                            <Link key={product.slug} to={`/products/${product.slug}`}>
                                <Card className="mb-3 shadow" key={product.id} style={{ width: "15em" }}>
                                    <Img
                                        fluid={product.photos[0].node.childImageSharp.fluid}
                                        key={product.photos[0].node.childImageSharp.id}
                                    />
                                    <Card.Body>
                                        <Card.Title>{product.name}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        ))}
                    </CardDeck>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <Card className="p-4 shadow-sm">
                        <Card.Body className="flex-fill">
                            <Card.Text>
                                <p className="lead">
                                    Curious about our other hats? <Link to="/products">See all our products.</Link>
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default Featured

export const pageQuery = graphql`
query NewProductQuery {
    gcms {
        productsApi(where: {itemStatus: NEW_ITEM}) {
          id
          name
          slug
          category
          photos(first: 1) {
            url
            node {
              childImageSharp {
                id
                fluid (maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
}`